import React, { useEffect, useState } from "react"

import { InfoCircleOutlined } from "@ant-design/icons"
import { Skeleton } from "antd"
import Text from "components/atom/text"
import { Rendering } from "components/molecule/loading"
import { StateMachineType } from "libs/statemachine"

import ManageDate from "@/libs/date"
import { useAccountStore } from "@/store/account/useAccountStore"

import AccountUserInfo from "../UserAccount/accountUserInfo"

const CardAccountInfo = ({
  onSuccess,
  principalDriver = true,
}: {
  principalDriver?: boolean
  onSuccess?: () => void
}) => {
  const { accountInfo, executeAccountInfo } = useAccountStore()

  const [showAccountData, setShowAccountData] = useState(false)

  useEffect(() => {
    executeAccountInfo({ principalDriver })
  }, [principalDriver])

  const onHandleShowAccountData = () => {
    setShowAccountData((prev) => !prev)
  }

  const renderStateNotStarted = () => <Rendering />

  const renderStateLoading = () => (
    <div>
      <Skeleton active />
    </div>
  )

  const renderStateLoaded = () => (
    <div className="mb-4">
      <Text className="mb-2 text-2xl font-bold block">
        Precisamos de mais alguns dados seus
      </Text>

      <AccountUserInfo
        onSuccess={onSuccess}
        principalDriver={principalDriver}
      />
    </div>
  )

  const rendering = () => {
    switch (accountInfo.viewState) {
      case StateMachineType.NotStarted:
        return renderStateNotStarted()
      case StateMachineType.Loading:
        return renderStateLoading()
      case StateMachineType.Loaded:
        return renderStateLoaded()
      default:
        return renderStateLoading()
    }
  }

  if (!showAccountData && accountInfo.data?.completed) {
    return (
      <div className="mb-4">
        <div className="_text-1 mb-2">
          <InfoCircleOutlined className="_mr-2" />
          Pode ficar tranquilo, todos os dados são criptografados e armazenados
          com segurança.
        </div>
        <div className="mt-4 mb-2 text-2xl">Dados Pessoais</div>
        <div className="mb-4 grid grid-cols-2 md:grid-cols-3 gap-4">
          <div>
            <span className="block font-bold">Nome</span>
            <span className="block text-xl">{accountInfo.data?.name}</span>
          </div>
          <div>
            <span className="block font-bold">CPF</span>
            <span className="block text-xl">{accountInfo.data?.cpf}</span>
          </div>
          <div>
            <span className="block font-bold">Data de nascimento</span>
            <span className="block text-xl">
              {ManageDate.formatBrazil(accountInfo.data?.birthday)}
            </span>
          </div>
        </div>
        <div
          className="font-bold text-blue-800 cursor-pointer"
          onClick={onHandleShowAccountData}
        >
          <span>Ver todos os dados</span>
        </div>
      </div>
    )
  }

  return rendering()
}

export default CardAccountInfo
