import React, { useState, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { InfoCircleOutlined } from "@ant-design/icons"
import { Divider, Skeleton, Form, Alert, RadioChangeEvent } from "antd"
import Button from "components/atom/button"
import Text from "components/atom/text"
import FormRadioGroup from "components/molecule/form_radio_group"
import Footer from "components/organism/footer"
import Header from "components/organism/header"
import { Address } from "libs/address"
import ManageDate from "libs/date"
import { DEFAULT_ERROR_MESSAGE } from "libs/i18n/pt"
import StateMachine from "libs/statemachine"
import DescriptionVehicle from "pages/UserSearchVehicleAvailable/components/descriptionVehicle"
import { FeatureGetter } from "store/getter/Action"
import { useGetterStore } from "store/getter/Hooks"

import { FormDatePicker, FormInput, FormSwitch } from "@/components/molecule"
import { InputMask } from "@/components/molecule/form_input"
import { useAccountStore } from "@/store/account/useAccountStore"
import { FeatureExecuter } from "@/store/executer/Action"
import { useExecuterStore } from "@/store/executer/Hooks"

import { BookingSteps } from "../UserSearchVehicleAvailable/components/steps"
import CardAccountInfo from "./cardAccountInfo"
import ProtectionAndAdditional from "./components/protection_additionals"

type ProtectionAndAdditional = {
  uCode: string
  amount: number
  amountFormat: string
  billingType: string
  currencyCode: string
  days: number
  description: string
  name: string
  quantity: number
  total: number
  totalFormat: string
  totalQuantity: number
  totalQuantityFormat: string
}

type RentalRate = {
  additionals: Array<ProtectionAndAdditional>
  byHour: boolean
  dayCharge: {
    currencyCode: string
    key: string
    price: number
    priceFormat: string
    quantity: number
    total: number
    totalFormat: string
  }
  discountCharge: {
    currencyCode: string
    isValid: boolean
    key: string
    message: string
    percentage: number
    percentageFormat: string
    total: number
    totalFormat: string
  }
  feeAdminstration: {
    percentage: number
    percentageFormat: string
    total: number
    totalFormat: string
  }
  feeCompanyBranch: {
    percentage: number
    percentageFormat: string
    total: number
    totalFormat: string
  }
  feeTotal: {
    percentage: number
    percentageFormat: string
    total: number
    totalFormat: string
  }
  protections: Array<ProtectionAndAdditional>
  totalCharge: {
    total: number
    totalFormat: string
  }
  summary: {
    currencyCode: string
    total: string
    totalAdditionals: string
    totalDays: string
    totalDiscount: string
    totalFee: string
    totalFeeCompanyBranch: string
    totalFeePlatform: string
    totalPayAtCompany: string
    totalPayAtPlatform: string
    totalProtections: string
    totalWithoutDiscount: string
  }
}

type PickupDropoff = {
  uCode: string
  local: string
  additionalInfo: string
  type: string
}

type Vehicle = {
  uCode: string
  vehicleGroup: string
  description: string
  doors: string
  groupTypeRegister: string
  kmUnlimited: boolean
  pictureURL: string
  airConditioning: boolean
  carGearbox: string
  seats: string
  typePayment: string
  typeUserPayment: string
  rentalRate: RentalRate
  company: {
    samePickupDropoff?: boolean
    uCode: string
    uCodeCompany: string
    address: Address
    pickup: Array<PickupDropoff>
    dropoff?: Array<PickupDropoff>
    color: string
    companyName: string
    companyTag: string
    email: string
    emailContact: string
    logo: string
    phone: string
    tradingName: string
    type: string
  }
  daysOpen: any
}

type DataRouteParams = {
  start: string
  end: string
  vehicle: Vehicle
}

const UserRentDetail = () => {
  const { t } = useTranslation()
  const controller = useRef<AbortController>()
  const history = useHistory<DataRouteParams>()
  const params = history.location.state

  const [rentalRate, setRentalRate] = useState<RentalRate>(
    params.vehicle.rentalRate,
  )
  const [rentError, setRentError] = useState<string | null>(null)
  const [pickupSelected, setPickup] = useState<PickupDropoff | undefined>()
  const [dropoffSelected, setDropoff] = useState<PickupDropoff | undefined>()
  const [principalDriver, setPrincipalDriver] = useState(true)

  const {
    accountInfo,
    accountUpdate,
    executeAccountInfo,
    executeAccountUpdateAsync,
  } = useAccountStore()

  const {
    data: dataProtectionAndEquipment,
    getData: getProtectionAndEquipment,
    viewState: viewStateProtectionAndEquipment,
  } = useGetterStore({
    feature: FeatureGetter.GetVehicleProtectionAndEquipment,
  })

  const { getData: getAccountValidate, viewState: viewStateAccountValidate } =
    useGetterStore({
      feature: FeatureGetter.GetAccountValidate,
    })

  const { executeAction: getBookingCharge, viewState: viewStateBookingCharge } =
    useExecuterStore({
      feature: FeatureExecuter.BookingCharge,
    })

  const { executeAction: createBooking, viewState: viewStateBooking } =
    useExecuterStore({
      feature: FeatureExecuter.Booking,
    })

  const [form] = Form.useForm()

  useEffect(() => {
    window.scrollTo(0, 0)

    getProtectionAndEquipment({
      queryParams: {
        end: params.end,
        start: params.start,
        uCodeCompanyBranch: params.vehicle.company.uCode,
        uCodeVehicleGroup: params.vehicle.uCode,
      },
    })

    form.setFieldValue("principalDriver", principalDriver)
  }, [])

  // Fix: Default Protection;
  // useEffect(() => {
  //   if (StateMachine.isLoaded(viewState)) {
  //     const coverageDefaults = params.vehicle.rentalRate.insurance.map(
  //       (insurance) => insurance.coverage.coverageType,
  //     )

  //     form.setFieldsValue({
  //       protections: coverageDefaults || [],
  //     })

  //     setProtections(coverageDefaults || [])
  //   }
  // }, [viewState])

  const calculateRentalRate = async () => {
    setRentError(null)

    if (controller.current) {
      controller.current?.abort()
      controller.current = new AbortController()
    } else {
      controller.current = new AbortController()
    }

    const { signal } = controller.current
    const fieldsValue = form.getFieldsValue()

    const result = await getBookingCharge({
      bodyParams: {
        additionals: fieldsValue.additionals
          ? fieldsValue.additionals.map((uCode: any) => ({
              quantity: 1,
              uCode,
            }))
          : [],
        cupom: fieldsValue.cupom || null,
        endDate: params.end,
        protections: fieldsValue.protections
          ? fieldsValue.protections.map((uCode: any) => ({
              quantity: 1,
              uCode,
            }))
          : [],
        startDate: params.start,
        uCodeCompanyBranch: params.vehicle.company.uCode,
        uCodeVehicleGroup: params.vehicle.uCode,
      },
      signal,
    })

    if (result && !result.hasError && result?.data) {
      setRentalRate(result.data)
    }
  }

  const onChangeProtection = () => {
    setRentError(null)
    calculateRentalRate()
  }

  const onChangeAdditionals = () => {
    calculateRentalRate()
  }

  const onBlurCupom = () => {
    calculateRentalRate()
  }

  const onSubmitPersonalData = async (form: any) => {
    setRentError(null)

    const submitData = {
      account: {
        birthday: ManageDate.formatDatabaseDate(form["account.birthday"]),
        cpf: form["account.cpf"],
        gender: form["account.gender"] || "",
        name: form["account.name"],
        phone: form["account.phone"],
      },
      address: {
        city: form["address.city"],
        complement: form["address.complement"] || "",
        neighborhood: form["address.neighborhood"],
        number: form["address.number"],
        state: form["address.state"],
        street: form["address.street"],
        typeStreet: form["address.typeStreet"],
        zipCode: form["address.zipCode"],
      },
    }

    await executeAccountUpdateAsync(submitData)
  }

  const onSuccessAccountForm = () => {
    setRentError(null)
  }

  const onSubmitForm = () => {
    form.submit()
  }

  const onSubmit = async () => {
    setRentError(null)

    const fieldsValue = form.getFieldsValue()

    if (fieldsValue.protections < 1) {
      setRentError("rent_error_protection")
      return
    }

    if (!accountInfo.data.completed) {
      await onSubmitPersonalData(fieldsValue)
    }

    const resultValidate = await getAccountValidate({
      queryParams: {
        principalDriver: principalDriver ? 1 : 0,
        uCode: params.vehicle.company.uCodeCompany,
      },
    })

    if (!resultValidate?.validated) {
      if (resultValidate?.reason) {
        setRentError(resultValidate.reason)
      } else if (resultValidate?.keyValidation) {
        setRentError(resultValidate.keyValidation)
      } else {
        setRentError(resultValidate.key)
      }

      executeAccountInfo({ principalDriver })
      return
    }

    const payload = {
      additionals: fieldsValue.additionals
        ? fieldsValue.additionals.map((uCode: any) => ({
            quantity: 1,
            uCode,
          }))
        : [],
      cupom: fieldsValue.cupom || null,
      endDate: params.end,
      flightNumber: fieldsValue.flightNumber,
      principalDriver: fieldsValue.principalDriver,
      principalDriverBirthDate: fieldsValue.principalDriverBirthDate,
      principalDriverCpf: fieldsValue.principalDriverCpf,
      principalDriverName: fieldsValue.principalDriverName,
      protections: fieldsValue.protections
        ? fieldsValue.protections.map((uCode: any) => ({
            quantity: 1,
            uCode,
          }))
        : [],
      startDate: params.start,
      uCodeCompanyBranch: params.vehicle.company.uCode,
      uCodeDropoff: fieldsValue.dropoff,
      uCodePickup: fieldsValue.pickup,
      uCodeVehicleGroup: params.vehicle.uCode,
    }

    const selectedPickup = params.vehicle.company.pickup.find(
      (pickup) => pickup.uCode === fieldsValue.pickup,
    )

    let selectedDropoff = null

    if (fieldsValue.dropoff) {
      selectedDropoff = params?.vehicle?.company?.dropoff?.find(
        (dropoff) => dropoff.uCode === fieldsValue.dropoff,
      )
    } else {
      selectedDropoff = selectedPickup
    }

    const result = await createBooking({
      bodyParams: payload,
    })

    if (result.hasError) {
      executeAccountInfo({ principalDriver })
      setRentError(result.data.key)
    } else {
      if (!result?.data?.reservationCode) {
        executeAccountInfo({ principalDriver })
        setRentError("booking_error_generic")
      } else {
        const vehicleData = {
          ...params.vehicle,
          selectedDropoff,
          selectedPickup,
        } as any

        history.push(
          `/pages/vehicle/rent/done?start=${params.start}&end=${params.end}&reservationCode=${result.data.reservationCode}`,
          vehicleData,
        )
      }
    }
  }

  const onChangePrincipalDriver = (data: boolean) => {
    setPrincipalDriver(data)
  }

  const onChangePickup = (e: RadioChangeEvent) => {
    const pickup = params.vehicle.company.pickup.find(
      (pickup) => pickup.uCode === e.target.value,
    )
    setPickup(pickup)
  }

  const onChangeDropoff = (e: RadioChangeEvent) => {
    const dropoff = params?.vehicle?.company?.dropoff?.find(
      (pickup) => pickup.uCode === e.target.value,
    )

    setDropoff(dropoff)
  }

  const getFormInitialValues = () => {
    return {
      dropoff: params.vehicle.company.samePickupDropoff
        ? params.vehicle.company.pickup[0].uCode
        : params.vehicle.company?.dropoff?.[0].uCode,
      pickup: params.vehicle.company.pickup[0].uCode,
    }
  }

  return (
    <div>
      <Header />
      <div className="px-4 bg-slate-200 flex justify-center">
        <div className="max-w-[1280px] w-full">
          <div className="bg-white px-4 py-4 mt-4 rounded-md">
            <BookingSteps current={2} />
          </div>

          <div className="mb-6 flex flex-col md:flex-row mt-4">
            {/** Booking Content */}
            <div className="flex-1 bg-white px-4 py-4 rounded-md">
              <div className="mb-4 flex items-center bg-slate-50 rounded-md border-slate-100 border-2">
                <img
                  className="h-[52px] rounded-md object-cover p-2"
                  src={params.vehicle.company.logo}
                />
                <div className="ml-2">
                  <span className="text-xs font-semibold block">Locadora</span>
                  <span className="text-sm overflow-hidden text-ellipsis line-clamp-1">
                    {params.vehicle.company.tradingName}
                  </span>
                </div>
              </div>

              <Form
                form={form}
                initialValues={getFormInitialValues}
                onFinish={onSubmit}
              >
                <div>
                  <Text className="mb-4 text-base block">
                    {!params.vehicle.company.samePickupDropoff
                      ? "Você irá retirar seu veículo"
                      : "Você irá retirar e devolver seu veículo"}
                  </Text>
                  <FormRadioGroup
                    direction="vertical"
                    name="pickup"
                    onChange={(e) => onChangePickup(e)}
                    options={params.vehicle.company.pickup.map((pickup) => ({
                      description: pickup.additionalInfo,
                      label: pickup.local,
                      value: pickup.uCode,
                    }))}
                    radioBoxClassName="border-2 rounded-md px-4 py-2"
                    radioDescriptionClassName="italic"
                    radioLabelClassName="mt-1 text-base font-bold"
                    required
                    requiredMessage="Você deve selecionar um endereço."
                    wrap={false}
                  />
                </div>
                {!params.vehicle.company.samePickupDropoff && (
                  <div>
                    <Text className="text-lg block mb-4">
                      Você irá devolver seu veículo
                    </Text>
                    <FormRadioGroup
                      direction="vertical"
                      name="dropoff"
                      onChange={(e) => onChangeDropoff(e)}
                      options={
                        params.vehicle.company?.dropoff?.map((dropoff) => ({
                          description: dropoff.additionalInfo,
                          label: dropoff.local,
                          value: dropoff.uCode,
                        })) || []
                      }
                      radioBoxClassName="border-2 rounded-md px-4 py-2"
                      radioDescriptionClassName="italic"
                      radioLabelClassName="mt-1 text-base font-bold"
                      required
                      wrap={false}
                    />
                  </div>
                )}
                <div>
                  <div>
                    <FormSwitch
                      checkedChildren="Sim"
                      label="Sou o condutor principal"
                      name="principalDriver"
                      onChange={onChangePrincipalDriver}
                      required
                      unCheckedChildren="Não"
                    />

                    {!principalDriver && (
                      <div>
                        <div className="mt-4 mb-2 text-2xl">
                          Dados do condutor principal
                        </div>

                        <FormInput
                          label="Nome do conduto principal"
                          name="principalDriverName"
                          required
                        />
                        <div className="mt-2 grid grid-cols-12 gap-4">
                          <div className="col-span-12 md:col-span-6">
                            <FormInput
                              label="CPF do conduto principal"
                              mask={InputMask.CPF}
                              name="principalDriverCpf"
                              required
                            />
                          </div>
                          <div className="col-span-12 md:col-span-6">
                            <FormDatePicker
                              label="Data de nascimento do conduto principal"
                              mobileOptions={{
                                hideNavigation: false,
                                numberOfMonths: 12,
                                withSelectYear: true,
                              }}
                              name="principalDriverBirthDate"
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <CardAccountInfo
                      onSuccess={onSuccessAccountForm}
                      principalDriver={principalDriver}
                    />
                  </div>
                  <Divider />
                  {pickupSelected?.type === "airport" && (
                    <>
                      <div>
                        <Text className="mb-2 text-2xl font-bold block">
                          Informações do seu voo
                        </Text>
                        <div className="_text-1 mb-2">
                          <InfoCircleOutlined className="_mr-2" />
                          Essa informação é importante para podermos já deixar
                          tudo preparado para você no horário do seu voo.
                        </div>
                        <FormInput label="Número do voo" name="flightNumber" />
                      </div>
                      <Divider />
                    </>
                  )}
                  <div>
                    <Text className="mb-2 text-2xl font-bold block">
                      Descontos e promoções
                    </Text>
                    <FormInput
                      className="mb-2"
                      label="Código do cupom"
                      name="cupom"
                      onBlur={onBlurCupom}
                    />
                    <Text className="text-sm font-bold">
                      {rentalRate.discountCharge.isValid
                        ? rentalRate.discountCharge.message
                        : ""}
                    </Text>
                  </div>
                  <Divider />
                  <div className="mb-4">
                    <Text className="mb-2 text-3xl font-bold block">
                      Complete seu aluguel
                    </Text>
                    <Text className="mb-2">
                      Selecione todos os adicionais que deseja incluir na sua
                      reserva.
                    </Text>
                  </div>
                  <div>
                    <div className="mb-2">
                      <Text className="text-xl font-bold text-orange-900">
                        Mais proteção para sua reserva
                      </Text>
                    </div>
                    {StateMachine.isLoading(
                      viewStateProtectionAndEquipment,
                    ) && <Skeleton active />}
                    {StateMachine.isLoaded(viewStateProtectionAndEquipment) && (
                      <ProtectionAndAdditional
                        name="protections"
                        onChange={onChangeProtection}
                        options={dataProtectionAndEquipment.protections.map(
                          (data: any) => ({
                            data,
                            label: data.info.name,
                            value: data.info.uCode,
                          }),
                        )}
                        type="protection"
                      />
                    )}
                  </div>
                  <div>
                    <div className="mb-2">
                      <Text className="text-xl font-bold text-orange-900">
                        Adicionais e Acessórios
                      </Text>
                    </div>
                    {StateMachine.isLoading(
                      viewStateProtectionAndEquipment,
                    ) && <Skeleton active />}
                    {StateMachine.isLoaded(viewStateProtectionAndEquipment) && (
                      <ProtectionAndAdditional
                        name="additionals"
                        onChange={onChangeAdditionals}
                        options={dataProtectionAndEquipment.additionals.map(
                          (data: any) => ({
                            data,
                            label: data.info.name,
                            value: data.info.uCode,
                          }),
                        )}
                        type="equipment"
                      />
                    )}
                  </div>
                </div>
              </Form>
            </div>

            {/** Booking Resume */}
            <div className="md:ml-4 md:w-[512px]">
              <div className="mt-6 md:mt-0 bg-white rounded-md drop-shadow-sm">
                <div className="py-4 text-center bg-orange-200 rounded-t-md border-x border-t border-slate-200">
                  <Text className="text-lg font-bold text-orange-900 block">
                    {params.vehicle.vehicleGroup}
                  </Text>
                  <Text className="text-xs text-orange-900">
                    grupo escolhido
                  </Text>
                </div>
                <div className="px-4 py-4 text-center rounded-b-md border-x border-b border-slate-200">
                  <Text className="mb-4 text-base block">
                    {params.vehicle.description}
                  </Text>

                  <DescriptionVehicle
                    className="flex justify-center items-center"
                    vehicle={params.vehicle}
                  />

                  <div className="mt-4">
                    <Text className="text-xs italic">
                      * A sua reserva garante um dos carros desse grupo. Modelo
                      sujeito à disponibilidade.
                    </Text>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-md mt-6 drop-shadow-sm">
                <div className="text-center bg-orange-200 rounded-t-md py-4 border-x border-t border-slate-200">
                  <Text className="text-lg font-bold text-orange-900">
                    Resumo da reserva
                  </Text>
                </div>
                <div className="px-4 py-4 border-x-2 border-b-none border-slate-200">
                  <Text className="text-lg font-bold block">Retirada</Text>
                  {pickupSelected?.local && (
                    <Text className="text-sm block">
                      {pickupSelected?.local}
                    </Text>
                  )}

                  <Text className="mt-1 text-sm block">
                    {ManageDate.friendlyFormat(params.start)}
                  </Text>
                  <Divider />
                  <Text className="text-lg font-bold block">Devolução</Text>
                  {params.vehicle.company.samePickupDropoff ? (
                    <>
                      {pickupSelected?.local && (
                        <Text className="text-sm block">
                          {pickupSelected?.local}
                        </Text>
                      )}
                    </>
                  ) : (
                    <>
                      {dropoffSelected?.local && (
                        <Text className="text-sm block">
                          {dropoffSelected?.local}
                        </Text>
                      )}
                    </>
                  )}

                  <Text className="mt-1 text-sm block">
                    {ManageDate.friendlyFormat(params.end)}
                  </Text>
                  <Divider />
                  <div className="flex">
                    <div className="flex-1">
                      <Text className="text-lg font-bold block">Oferta</Text>
                    </div>
                    <Text className="text-base font-bold block">Total</Text>
                  </div>
                  <div className="mt-2 flex">
                    <div className="flex-1">
                      <Text className="text-sm font-bold block">Diárias</Text>
                      <Text className="text-sm block">
                        {rentalRate.dayCharge.quantity}x{" "}
                        {rentalRate.dayCharge.priceFormat}
                      </Text>
                    </div>
                    <div className="text-end">
                      <Text className="text-sm font-bold block">&nbsp;</Text>
                      <Text className="text-sm block">
                        {rentalRate.dayCharge.totalFormat}
                      </Text>
                    </div>
                  </div>
                  {StateMachine.isLoading(viewStateBookingCharge) ? (
                    <Skeleton active className="mt-4" />
                  ) : (
                    <div>
                      <div className="mt-4">
                        {(rentalRate.protections.length > 0 ||
                          rentalRate.additionals.length > 0) && (
                          <Text className="text-sm font-bold block text-orange-900">
                            Proteções e adicionais
                          </Text>
                        )}
                        {rentalRate.protections.map((protection) => {
                          return (
                            <div className="mt-2 flex" key={protection.uCode}>
                              <div className="flex-1">
                                <Text className="text-sm font-bold block">
                                  {protection.name}&nbsp;
                                  <span className="text-xs">
                                    {`${protection.billingType === "D" ? "(por dia)" : ""}`}
                                  </span>
                                </Text>
                                <Text className="text-sm block">
                                  {protection.amountFormat}
                                </Text>
                              </div>
                              <div className="text-end">
                                <Text className="text-sm font-bold block">
                                  &nbsp;
                                </Text>
                                <Text className="text-sm block">
                                  {protection.totalFormat}
                                </Text>
                              </div>
                            </div>
                          )
                        })}
                        {rentalRate.additionals.map((additional) => {
                          return (
                            <div className="mt-2 flex" key={additional.uCode}>
                              <div className="flex-1">
                                <Text className="text-sm font-bold block">
                                  {additional.name}&nbsp;
                                  <span className="text-xs">
                                    {`${additional.billingType === "D" ? "(por dia)" : ""}`}
                                  </span>
                                </Text>
                                {additional.billingType === "D" ? (
                                  <Text className="text-sm block">
                                    {additional.quantity}x&nbsp;
                                    {additional.amountFormat}
                                  </Text>
                                ) : (
                                  <Text className="text-sm block">
                                    {additional.amountFormat}
                                  </Text>
                                )}
                              </div>
                              <div className="text-end">
                                <Text className="text-sm font-bold block">
                                  &nbsp;
                                </Text>
                                <Text className="text-sm block">
                                  {additional.totalFormat}
                                </Text>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                      {rentalRate.discountCharge?.isValid && (
                        <div className="mt-4">
                          <Text className="text-sm font-bold block text-orange-900">
                            Descontos e promoções
                          </Text>
                          <div className="mt-2 flex">
                            <div className="flex-1">
                              <Text className="text-sm font-bold block">
                                Desconto
                              </Text>
                              {rentalRate.discountCharge.percentage > 0 && (
                                <Text className="text-sm block">
                                  {rentalRate.discountCharge.percentageFormat}
                                </Text>
                              )}
                            </div>
                            <div className="text-end">
                              {rentalRate.discountCharge.percentage > 0 && (
                                <Text className="text-sm font-bold block">
                                  &nbsp;
                                </Text>
                              )}
                              <Text className="text-sm block">
                                {rentalRate.discountCharge.totalFormat}
                              </Text>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="mt-4 flex">
                        <div className="flex-1">
                          <Text className="text-sm font-bold block">Taxas</Text>
                          <Text className="text-sm block">
                            {rentalRate.feeTotal.percentageFormat}
                          </Text>
                        </div>
                        <div className="text-end">
                          <Text className="text-sm font-bold block">
                            &nbsp;
                          </Text>
                          <Text className="text-sm block">
                            {rentalRate.feeTotal.totalFormat}
                          </Text>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="px-4 py-4 bg-closecar-primary text-end rounded-b-md border-x border-b border-slate-200">
                  <Text className="mb-2 text-lg font-bold text-orange-900 block">
                    Total previsto
                  </Text>
                  <Text className="text-4xl font-black text-white block">
                    {StateMachine.isLoading(viewStateBookingCharge) ? (
                      <Skeleton.Button active block shape="default" />
                    ) : (
                      <>{rentalRate.summary.total}</>
                    )}
                  </Text>
                </div>
              </div>

              <div className="mt-4">
                <Button
                  className="mb-6 w-full"
                  disabled={
                    StateMachine.isLoading(viewStateProtectionAndEquipment) ||
                    StateMachine.isLoading(viewStateBookingCharge) ||
                    StateMachine.isLoading(accountUpdate.viewState)
                  }
                  loading={
                    StateMachine.isLoading(viewStateBooking) ||
                    StateMachine.isLoading(viewStateAccountValidate)
                  }
                  onClick={onSubmitForm}
                  theme="secondary"
                >
                  Confirmar reserva
                </Button>
              </div>

              {rentError && (
                <Alert
                  closable
                  message={t([rentError, DEFAULT_ERROR_MESSAGE])}
                  showIcon
                  type="error"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default UserRentDetail
