import React, { useEffect } from "react"

import { InfoCircleOutlined } from "@ant-design/icons"
import { Skeleton, Divider, Form } from "antd"
import FormInput, { InputMask } from "components/molecule/form_input"
import FormSelect from "components/molecule/form_select"
import { Rendering } from "components/molecule/loading"
import StateMachine, { StateMachineType } from "libs/statemachine"

import { Button } from "@/components/atom"
import FormDatePicker from "@/components/molecule/form_datepicker"
import ManageDate from "@/libs/date"
import { useAccountStore } from "@/store/account/useAccountStore"
import { FeatureGetter } from "@/store/getter/Action"
import { useGetterStore } from "@/store/getter/Hooks"

import { brazilStatesOption, typeAddressOption } from "./data"
import TakePhotoDocumentModal, {
  DocumentUploadType,
  useTakePhotoDocument,
} from "./takePhotoDocumentModal"

const AccountUserInfo: React.FC<any> = ({
  onSuccess,
  principalDriver = true,
}: {
  onSuccess?: () => void
  principalDriver?: boolean
}) => {
  const form = Form.useFormInstance()

  const { ref: refCNH, show: showCNH } = useTakePhotoDocument()
  const {
    accountInfo,
    accountUpdate,
    executeAccountInfo,
    executeAccountUpdateAsync,
  } = useAccountStore()

  const { getData: getCep, viewState: viewStateCep } = useGetterStore({
    feature: FeatureGetter.GetCEP,
  })

  const formSetDefaultValues = () => {
    if (!StateMachine.isLoaded(accountInfo.viewState)) {
      return
    }

    const formValues = form.getFieldsValue()

    // Caso tenha valor já no form mantem ele, se não inclui o da API;
    form.setFieldsValue({
      "account.birthday":
        formValues["account.birthday"] || accountInfo.data?.birthday
          ? ManageDate.mountDate(accountInfo.data?.birthday)
          : undefined,
      "account.cpf": formValues["account.cpf"] || accountInfo.data?.cpf,
      "account.gender":
        formValues["account.gender"] || accountInfo.data?.gender,
      "account.name": formValues["account.name"] || accountInfo.data?.name,
      "account.phone": formValues["account.phone"] || accountInfo.data?.phone,
      "address.city":
        formValues["address.city"] || accountInfo?.data.address?.city,
      "address.complement":
        formValues["address.complement"] ||
        accountInfo.data?.address?.complement,
      "address.neighborhood":
        formValues["address.neighborhood"] ||
        accountInfo.data?.address?.neighborhood,
      "address.number":
        formValues["address.number"] || accountInfo.data?.address?.number,
      "address.state":
        formValues["address.state"] || accountInfo.data?.address?.state,
      "address.street":
        formValues["address.street"] || accountInfo.data?.address?.street,
      "address.typeStreet":
        formValues["address.typeStreet"] ||
        accountInfo.data?.address?.typeStreet,
      "address.zipCode":
        formValues["address.zipCode"] || accountInfo.data?.address?.zipCode,
    })
  }

  useEffect(() => {
    formSetDefaultValues()
  }, [accountInfo.viewState])

  const onSuccessPhotoCNH = async (data: any) => {
    await executeAccountUpdateAsync({
      document: {
        type: "document_cnh",
        value: data.fileKey,
      },
    })

    onSuccess?.()
    executeAccountInfo({ principalDriver })
  }

  const onSearchCEP = async (value: any) => {
    const result = await getCep({ queryParams: { cep: value } })

    form.setFieldsValue({
      "address.city": result.city,
      "address.neighborhood": result.neighborhood,
      "address.state": result.state,
      "address.street": result.street,
      "address.typeStreet": result.typeStreet,
    })
  }

  const onBlurCEP = (data: React.ChangeEvent<HTMLInputElement>) => {
    if (data.target.value.length === 9) {
      onSearchCEP(data.target.value)
    }
  }

  const onChangeCEP = (value: any) => {
    if (value.length === 9) {
      onSearchCEP(value)
    }
  }

  const renderStateNotStarted = () => <Rendering />

  const renderStateLoading = () => (
    <div className="ml-4 md:ml-0">
      <Skeleton active />
    </div>
  )

  const renderStateLoaded = () => (
    <div className="mb-4">
      <div className="_text-1 mb-2">
        <InfoCircleOutlined className="_mr-2" />
        Pode ficar tranquilo, todos os dados são criptografados e armazenados
        com segurança.
      </div>

      {principalDriver && (
        <>
          <div className="mt-4 mb-2 text-2xl">Dados da habilitação</div>
          {accountInfo.data?.cnhExpirationDate &&
            accountInfo.data?.cnhNumber && (
              <div className="mb-4 grid grid-cols-2 md:grid-cols-3 gap-4">
                <div>
                  <span className="block font-bold">Número da CNH</span>
                  <span className="block text-xl">
                    {accountInfo.data?.cnhNumber}
                  </span>
                </div>
                <div>
                  <span className="block font-bold">Data de expiração</span>
                  <span className="block text-xl">
                    {ManageDate.formatBrazil(
                      accountInfo.data?.cnhExpirationDate,
                    )}
                  </span>
                </div>
              </div>
            )}
          <div>
            <TakePhotoDocumentModal
              allowedExtensions={[
                "image/jpg",
                "image/jpeg",
                "image/png",
                "application/pdf",
              ]}
              documentType={DocumentUploadType.CNH}
              initalPhotoDocumentUrl={accountInfo.data?.cnhPhotoUrl}
              onSuccess={onSuccessPhotoCNH}
              ref={refCNH}
              title="Foto da CNH do condutor"
            />

            <div className="text-sm mb-2">
              <InfoCircleOutlined className="_mr-2" />A foto deve conter a
              frente e o verso do documento juntos. As extensões aceitas são
              JPEG, PNG ou PDF.
            </div>
            <Button
              block
              className="mt-2 _button_default_action"
              loading={StateMachine.isLoading(accountUpdate?.viewState)}
              onClick={() => showCNH()}
              theme="primary"
              type="button"
            >
              {accountInfo.data?.cnhPhotoUrl ? "Ver" : "Enviar"} foto da CNH do
              condutor
            </Button>
          </div>

          <Divider />
        </>
      )}

      <div className="mt-4 mb-2 text-2xl">Dados Pessoais</div>
      <div className="grid grid-cols-12 md:gap-4">
        <div className="col-span-12 md:col-span-6">
          <FormInput
            className="mb-2 md:mb-4"
            label="Nome"
            name="account.name"
            required
          />
        </div>
        <div className="col-span-12 md:col-span-6">
          <FormInput
            className="mb-2 md:mb-4"
            label="DDD+Telefone"
            mask={InputMask.DynamicPhone}
            name="account.phone"
            required
          />
        </div>
      </div>
      <div className="grid grid-cols-12 md:gap-4">
        <div className="col-span-12 md:col-span-4">
          <FormSelect
            className="mb-2 md:mb-0"
            label="Gênero"
            name="account.gender"
            option={[
              { text: "Feminino", value: "F" },
              { text: "Masculino", value: "M" },
              { text: "Não Binário", value: "NB" },
            ]}
          />
        </div>
        <div className="col-span-12 md:col-span-4">
          <FormDatePicker
            className="mb-2 md:mb-0"
            label="Data de nascimento"
            mobileOptions={{
              hideNavigation: false,
              numberOfMonths: 12,
              withSelectYear: true,
            }}
            name="account.birthday"
            required
          />
        </div>
        <div className="col-span-12 md:col-span-4">
          <FormInput
            className="mb-0"
            label="CPF"
            mask={InputMask.CPF}
            name="account.cpf"
            required
          />
        </div>
      </div>

      <Divider />
      <div className="mt-4 mb-2 text-2xl">Endereço</div>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-6">
          <FormInput
            className="mb-2 md:mb-4"
            disabled={StateMachine.isLoading(viewStateCep)}
            label="CEP"
            loading={StateMachine.isLoading(viewStateCep)}
            mask={InputMask.CEP}
            name="address.zipCode"
            onBlur={onBlurCEP}
            onChange={onChangeCEP}
            required
          />
        </div>
      </div>

      <div>
        <div className="grid grid-cols-12 md:gap-4">
          <div className="col-span-12 md:col-span-3">
            <FormSelect
              className="mb-2 md:mb-4"
              disabled={StateMachine.isLoading(viewStateCep)}
              label="Tipo do endereço"
              name="address.typeStreet"
              option={typeAddressOption}
              required
            />
          </div>
          <div className="col-span-12 md:col-span-7">
            <FormInput
              className="mb-2 md:mb-4"
              disabled={StateMachine.isLoading(viewStateCep)}
              label="Endereço"
              name="address.street"
              required
            />
          </div>
          <div className="col-span-12 md:col-span-2">
            <FormInput
              className="mb-2 md:mb-4"
              label="Número"
              name="address.number"
              required
            />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-12 md:gap-4">
        <div className="col-span-12 md:col-span-6">
          <FormInput
            className="mb-2 md:mb-4"
            disabled={StateMachine.isLoading(viewStateCep)}
            label="Complemento"
            name="address.complement"
          />
        </div>
      </div>

      <div className="grid grid-cols-12 md:gap-4">
        <div className="col-span-12 md:col-span-4">
          <FormInput
            className="mb-2 md:mb-0"
            disabled={StateMachine.isLoading(viewStateCep)}
            label="Bairro"
            name="address.neighborhood"
            required
          />
        </div>
        <div className="col-span-12 md:col-span-4">
          <FormInput
            className="mb-2 md:mb-0"
            disabled={StateMachine.isLoading(viewStateCep)}
            label="Cidade"
            name="address.city"
            required
          />
        </div>
        <div className="col-span-12 md:col-span-4">
          <FormSelect
            className="mb-2 md:mb-0"
            disabled={StateMachine.isLoading(viewStateCep)}
            label="Estado"
            name="address.state"
            option={brazilStatesOption}
            required
          />
        </div>
      </div>
    </div>
  )

  const rendering = () => {
    switch (accountInfo.viewState) {
      case StateMachineType.NotStarted:
        return renderStateNotStarted()
      case StateMachineType.Loading:
        return renderStateLoading()
      case StateMachineType.Loaded:
        return renderStateLoaded()
      default:
        return renderStateLoading()
    }
  }

  return rendering()
}

export default AccountUserInfo
